fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

legend {
  padding: 0;
}

label {
  font-size: var(--font-size-small);
  margin-bottom: 5px;
  display: block;
}

input:not([type="submit"], [type="checkbox"], [type="radio"]),
textarea {
  appearance: none;
  color: var(--color-petroleum);
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  &::placeholder {
    opacity: 0.75;
  }
  &::selection {
    color: var(--color-white);
    background-color: var(--color-black);
  }

  &:focus {
    &::placeholder {
      opacity: 0.5;
    }
  }

  + input:not([type="submit"], [type="checkbox"], [type="radio"]),
  textarea {
    /* margin-top: -1rem; */
  }
  &::placeholder {
    color: var(--color-gray) !important;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
  }
}

textarea {
  border: 1px solid currentColor;
  padding: 1rem 0.5rem;
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  border-radius: 0;
  resize: vertical;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: var(--font-size-small);
  user-select: none;

  &:hover {
    input ~ .checkmark {
      opacity: 1;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checklabel {
      font-weight: var(--font-weight-bold);
    }

    &:checked ~ .checkmark {
      opacity: 1;

      &:after {
        display: block;
      }
    }
  }

  .checklabel {
    font-size: var(--font-size-small);
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid var(--color-white);
    opacity: 0.5;
    transition: opacity 0.2s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0;
      width: 7px;
      height: 10px;
      border: solid var(--color-white);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

button {
  font-family: var(--font-hill);
  font-size: var(--font-size-body);
  &:focus {
    outline: none;
  }
}
