/* @import "../../styles/variables.css"; */
div[data-component="header"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 700;
  width: 100%;
  padding-left: var(--padding-site-mobile);
  padding-right: var(--padding-site-mobile);
  background-color: var(--color-white);

  @media (--mq-desktop) {
    position: fixed;
    padding-left: var(--padding-site-desktop);
    padding-right: var(--padding-site-desktop);
    .compact-navigation & {
      position: relative;
      padding: 0;
    }
  }
}
.compact-navigation {
  @media screen and (min-width: 1024px) {
    .logo + .row,
    .navigation-right {
      display: none;
    }
    .header-bg-container {
      padding: 0;
    }
  }
  .main {
  }
  .active-selfservice header {
    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }
}
header {
  color: var(--color-black);
  padding: 25px 0 15px;
  background-color: var(--color-white);
  @media screen and (min-width: 768px) {
    //padding: 0px 0 25px;
    padding: 0;
  }
  .active-selfservice & {
    //padding-bottom: 60px;
    @media screen and (min-width: 768px) {
      //padding-bottom: 80px;
    }
  }

  position: relative;
  .logo {
    position: absolute;
    max-width: 98px;
    left: 10px;
    top: 10px;
    
    @media screen and (min-width: 768px) {
      top:35px;
      #dgc &{
        max-width: 150px;
        top:calc(50px);
        transform: translateY(-25%);      
      }
      #gasfakta &{
        max-width: 150px;
        top:calc(34px);
      }
    }
    z-index: 510;
    img {
      width: auto;
    }
    svg {
      width: 100%;
      height: 100%;
      max-height: 50.5px;
      stroke:none;
    }
  }
  .close-container {
    z-index: 510;
    opacity: 0;
    animation: hideClose 0.1s ease-in-out backwards 0.1s;

    .active-menu & {
      animation: showClose 0.3s ease-in-out forwards 0.4s;
    }
    @media screen & (min-width: 1200px) {
      display: none;
    }
  }
}

.main-navigation {
  display: inline-block;
  float: right;
  .compact-navigation & {
    display: none;
  }
  @media screen and (min-width: 1200px) {
    float: none;
    display: block;
  }
  .menulist {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    background-color: var(--color--dark-green);
    margin-bottom: 30px;
    width: calc(100vw - (100% / 12));
    margin-left: calc(100% / 12);
    @media screen and (min-width: 768px) {
      width: calc(100vw - (100% / 12 * 2));
      margin-left: calc(100% / 12 * 2);
    }
    @media screen and (min-width: 1200px) {
      justify-content: flex-start;
      width: auto;
      margin-left: 0;
      flex-direction: row;
      background-color: transparent;
      padding: 0;
      height: auto;
    }
  }
  .menulist-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    width: 100vw;
    z-index: 500;
    transition: height 0.3s ease-in;
    background-color: var(--color-dark-green);

    @media screen and (min-width: 1200px) {
      background-color: transparent;
      width: auto;
      position: relative;
      height: auto;
    }
    .active-menu & {
      height: 100vh;
      overflow-y: auto;
    }
  }
  .menuitem {
    flex-grow: 1;
    align-self: stretch;
    padding: 7px;
    &:first-child {
      margin-top: 95px;
    }
    @media screen and (min-height: 568px) {
      &:first-child {
        margin-top: 210px;
      }
    }

    @media screen and (min-width: 768px) {
      &:first-child {
        margin-top: 228px;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
      color: var(--color-white);
      font-size: var(--font-size-header-medium);
      line-height: var(-line-height-medium-header);
      @media screen and (hover: hover) {
        &:hover {
          color: var(--color-green);
        }
      }
      &.active {
        color: var(--color-green);
      }
    }

    @media screen and (min-width: 1200px) {
      margin-right: 30px;
      flex-grow: 0;
      flex-basis: auto;
      padding: 0;
      &:first-child {
        margin-top: 0;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        color: var(--color-petroleum);
        font-size: var(--font-size-body);
        line-height: var(-line-height-body);
        @media screen and (hover: hover) {
          &:hover {
            color: var(--color-green);
          }
        }
        &.active {
          color: var(--color-green);
        }
      }
    }
  }
  .menulist-activation {
    text-decoration: none;
    font-weight: 700;
    @media screen and (hover: hover) {
      &:hover {
        color: var(--color-dark-green);
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}

.navigation-right {
  position: absolute;
  display: flex;
  top: 16px;
  right:65px;
  #dgc &{
    right:50px;
  }
  #gasfakta &{
    right:50px;
  }
  .compact-navigation & {
    display: none;
  }
  @media screen and (min-width: 768px) {
    #gasfakta &{
      right:50px;
    }
    right: 65px;
    top: 41px;
  }

  @media (--mq-desktop){
    #gasfakta &{
      right:90px;
    }
    #dgc &{
      right:90px;
    }
    right: 110px;

  }

  @media screen and (min-width: 1200px) {
    #gasfakta &{
      right:0px;
    }
    #dgc &{
      right:0px;
    }
    right: 20px;
    z-index: 510;
  }
}

.header-icon {
  height: 24px;
  width: 24px;
  border-radius: 254px;
  background-color: var(--color-green-10);
  flex-basis: 24px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.header-icon-lock{
  padding-top:2px;
}

.navigation-button {
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: none;
  padding: 8px 0;
  transition: all 0.2s ease-in-out;
  #svg-login,
  #svg-search {
    stroke: var(--color-green);
    fill: none;
  }
  @media screen and (hover: hover) {
    &:hover {
      background-color: var(--color-green);
      .button-text {
        color: var(--color-white);
      }
      #svg-search {
        stroke: var(--color-white);
      }
      #svg-lock{
        path{
          stroke:var(--color-white);
        }
      }
      .header-icon {
        background-color: var(--color-dark-green);
      }
    }
  }

  @media screen and (min-width: 768px) {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 8px 10px 8px 15px;
  }
  cursor: pointer;
  .button-text {
    display: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    margin-right: 10px;
    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }
  &.mybutton {
    @media (--mq-tablet) {
      background-color: var(--color-green);
    } 
    .button-text {
      color: var(--color-white);
    }
      #svg-lock {
        path {
          stroke: var(--color-white);
        }
      }
    .header-icon {
      background-color: var(--color-dark-green);
    }
    &:hover {
      @media (--mq-tablet) {
        background-color: var(--color-dark-green);
      }
    }
  }
}

.navigation-search {
  margin-right: 14px;
}

.search-box {
  margin-bottom: 0;
  border-radius: 0 0 5px 5px;
  input[type="text"]:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
    background-color: var(--color-white);
    border: none;
    border-radius: 5px;
    margin-bottom: 0;
    padding: 0;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--color-petroleum);
    width: 100%;
  }
  button {
    width: 100%;
    height: 50px;
    align-self: flex-end;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 30px;
  }
  .search-button {
    &:hover {
      background-color: var(--color-green);
    }
  }

  .close {
    background: transparent;
    position: relative;
    font-size: 14px;
    padding-right: 34px;
    text-align: right;
    margin-top: 5px;
    color: var(--color-white);
    transition: all 0.2s ease-in-out;
    @media screen and (min-width: 768px) {
      margin-top: 30px;
    }
    &::before,
    &::after {
      position: absolute;
      right: 15px;
      content: " ";
      height: 23px;
      width: 2px;
      background-color: var(--color-green);
      top: 14px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    @media screen and (hover: hover) {
      &:hover {
        &::before,
        &::after {
          background-color: var(--color-dark-green);
        }
      }
    }
  }
}

.search-container {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in;
  .row {
    padding-top: 0;
    padding-bottom: 10px;
    @media screen and (min-width: 1024px) {
      padding-top: 40px;
      padding-bottom: 60px;
    }
  }
}

#logo-svg {
  stroke: none;
  .a {
    fill: none;
  }
  .b {
    fill: #37ab49;
  }
  .c {
    clip-path: url(#a);
  }
  .d {
    fill: #2b8640;
  }
  .e {
    opacity: 0.45;
  }
  .f {
    clip-path: url(#c);
  }
  .g {
    fill: #fff;
  }
  .h {
    fill: #0c504e;
    .active-menu & {
      fill: var(--color-white);
    }
  }
}

.self-repporting {
  position: fixed;
  right: 0px;
  width:45vw;
  background-color: var(--color-white);
  padding-left: 30px;  
  bottom: 0;
  z-index:900;
  .compact-navigation & {
    bottom: 62px;
    z-index: 725;
    @media screen and (min-width: 460px) {
      top: 95px;
      right: calc(((100vw - 20px) / 12));
      bottom: auto;
    }
    @media screen and (min-width: 768px) {
      top: 120px;
      right: 90px;
      bottom: auto;
    }
    @media screen and (min-width: 1024px) {
      top: 38px;
    }
  }

  @media screen and (min-width: 768px) {
    border-radius: 40px;
    width: calc(100vw - 20px);
    position: absolute;
    right: 50px;
    top: 125px;
    width: auto;
    bottom: auto;
  }
  a {
    font-size: var(--font-size-small-medium);
    font-weight: 700;
    text-decoration: none;
    display: block;
    @media screen and (hover: hover) {
      &:hover {
        color: var(--color-green);
        .self-repporting-arrow {
          background-color: var(--color-green-10);
        }
      }
    }
  }
  span {
    display: inline-block;
    line-height: 48px;
  }
  .self-repporting-arrow {
    display:none;
    float: right;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    line-height: 48px;
    padding-top: 4px;

    background-color: var(--color-white);
    color: var(--color-dark-green);
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    margin-left: 10px;

    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.2);
    @media screen and (min-width: 768px) {
      display:inline-block;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    }
    background-color: var(--color-white);
    transition: all 0.3s ease-in-out;
    .svg-small-arrow-right {
      width: 10px;
      height: 20px;
    }
    svg {
      stroke: var(--color-green);
    }
  }
}

.header-top-bg {
  background-color: var(--color-white);
}

.header-bg-container {
  background-color: var(--color-white);
  @media screen and (min-width: 768px) {
    position:relative;
    padding-top: 50px;
  }
}

.site-switch-navigation{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:8px;
  text-decoration: none;
  margin-right:15px;
  @media (--mq-hdx){
    margin-right:30px;
  }
  .navigation-flag{
    width:16px;
  }
  span{
    display: none;
    font-size:var( --font-size-small);
    @media (--mq-hdx){
      display: inline-block;
    }
  }
}

@keyframes showClose {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.99;
  }
}

@keyframes hideClose {
  0% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }
}
