a.skip-to-content{
    top: 0;
    padding: 1.25rem;
    line-height: 0;
    text-transform: none;
    background-color: var(--color-black);
    position: fixed;
    z-index: 1000;
    color: var(--color-white);
    transform: translateX(-200px);
    transition: transform 350ms ease;


    &:focus,
    &:focus-visible{
        transform: translateX(0);
    }
}