.support-search {
  input[type="text"] {
    background: var(--color-white);
    width: 100%;
    border: 1px solid var(--color-black-40);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 30px 14px 31px;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  button.cta {
    align-self: flex-end;
    cursor: pointer;
    color: var(--color-white);
    margin-top: 20px;
    @media screen and (min-width: 1024px) {
      margin-top: 0;
    }
  }
  .tagline {
    margin: 10px 0 13px;
  }
  .cta {
    color: var(--color-petroleum);
    margin-right: 10px;
    @media screen and (hover: hover) {
      &:hover {
        color: var(--color-white);
      }
    }
  }

  .result {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    span {
      font-weight: 700;
    }
  }

  .support-search-popular {
    flex-direction: column;
    a {
      margin-bottom: 20px;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
}
