.download-component {
  a {
    display: flex;
    justify-content: flex-start;
    color: var(--color-petroleum);

    svg {
      width: 25px;
      height: 18px;
      margin-right: 20px;
      path {
        stroke: var(--color-green);
        transition: all 0.2s ease-in-out;
      }
    }
    @media screen and (hover: hover) {
      &:hover {
        svg {
          path {
            stroke: var(--color-white);
          }
        }
      }
    }
  }
  .support-content & {
    .row {
      justify-content: flex-end;
      margin-right: calc((100% / 12) * 2);
      & > div {
        text-align: right;
      }
      @media all and (-ms-high-contrast: none) {
        justify-content: flex-start;
        margin-right: 0;
        & > div {
          text-align: left;
        }
      }
    }

    a {
      display: inline;
      padding: 0;
      box-shadow: none;
      color: var(--color-green);
      background-color: transparent;
      @media screen and (hover: hover) {
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }
  + .download-component {
    margin-top: -30px;
    @media screen and (min-width: 1024px) {
      margin-top: -60px;
    }
  }
}
