.umb-forms {
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  .umbraco-forms-indicator {
    color: var(--color-orange);
  }

  @media (--mq-desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .umbraco-forms-caption{
    font-weight:var(--font-weight-bold);
    font-size:var(--font-size-header-medium);
  }



  legend{
    font-weight:var(--font-weight-bold);
    font-size:var(--font-size-header-small);
    line-height:var(--line-height-small-header);
    margin-bottom:15px;
  }

  .umbraco-forms-field {
    margin-bottom: 40px;
  }

  label,
  .field-validation-error {
    color: var(--color-petroleum);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    margin-bottom: 8px;
  }
  .field-validation-error {
    color: var(--color-orange);
  }
  input[type="text"],
  input[type="email"],
  textarea {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    font-family:var(--font-hill);
    &:focus {
      border-color: var(--color-green);
      outline: none;
    }
    &:invalid {
      border-color: var(--color-orange);
    }
  }

  .radiobuttonlist{
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: auto;
      li{        
        display: block;
        position: relative;
        float: left;
        width: 100%;
             
      }
    }

    ul li input[type=radio]{
      position: absolute;
      visibility: hidden;
    }

    ul li label{
      display: block;
      position: relative;
      padding: 14px 25px 14px 40px;
      margin: 10px auto;
      height: 30px;
      z-index: 9;
      cursor: pointer;
      -webkit-transition: all 0.25s linear;
    }

    ul li .check{
      display: block;
      position: absolute;
      border: 2px solid var(--color-orange);
      border-radius: 100%;
      height: 25px;
      width: 25px;
      top: 18px;
      left:0px;
      z-index: 5;
      transition: border .25s linear;
      -webkit-transition: border .25s linear;
    }

    ul li .check::before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 18px;
      width: 18px;
      top: 50%;
      left: 50%;
      transform: translateX(-49%) translateY(-53%);
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }

    input[type=radio]:checked ~ .check {
      border: 2px solid var(--color-green);
    }

    input[type=radio]:checked ~ .check::before{
      background: var(--color-green);
    }

    input[type=radio]:checked ~ label{
      color: var(--color-green);
    }

  }

  

  .mandatory {
    input[type="text"],
    input[type="email"],
    textarea {
      border-color: var(--color-orange);
    }
  }

  textarea {
    min-height: 315px;
    &::placeholder {
      color: var(--color-gray) !important;
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
    }
  }

  .dataconsent .umbraco-forms-field-wrapper {
    cursor: pointer;
    input {
      visibility: hidden;
      display: block;
      height: 1px;
    }
    label {
      display: block;
      padding-left: 44px;
      position: relative;
      line-height: 22px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: 2px solid var(--color-petroleum-50);
        border-radius: 4px;
      }
    }

    input:checked ~ label::before {
      content: "\2713";
      color: var(--color-green);
      border-color: var(--color-green);
      text-align: center;
      font-size: 20px;
      line-height: 18px;
    }
    input:invalid ~ label::before {
      border-color: var(--color-orange);
    }
  }

  .dataconsent.mandatory .umbraco-forms-field-wrapper {
    label {
      &::before {
        border-color: var(--color-orange);
      }
    }
  }

  
  .file-container {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    label {
      cursor: pointer;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding: 0 14px;
      }
    }
  }

  .cta-send {
    margin-top: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    @media screen and (min-width: 1024px) {
      margin-top: 20px;
    }
  }

  .multiplechoice .umbraco-forms-field-wrapper {
    cursor: pointer;
    input {
      visibility: hidden;
      display: block;
      height: 1px;
    }
    label {
      display: block;
      padding-left: 44px;
      position: relative;
      line-height: 22px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: 2px solid var(--color-petroleum-50);
        border-radius: 4px;
      }
    }

    input:checked + label::before {
      content: "\2713";
      color: var(--color-green);
      border-color: var(--color-green);
      text-align: center;
      font-size: 20px;
      line-height: 18px;
    }
    input:invalid + label::before {
      border-color: var(--color-orange);
    }
  }

}
