.richtext-component {
  color: var(--color-petroleum);
  a {
    color: var(--color-dark-green);
    transition: color 0.2s ease-in-out;
    @media screen and (hover: hover) {
      &:hover {
        color: var(--color-green);
      }
    }
    &.cta-button {
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      color: #ffffff;
      text-decoration: none;
      padding: 13px 30px;
      display: inline-block;
      border-color: transparent;
      transition: all 0.2s ease-in-out;
      background-color: var(--color-dark-green);
      @media screen and (hover: hover) {
        &:hover {
          color: #ffffff;
          background-color: var(--color-green);
        }
      }
    }
  }
  img {
    max-width: 100%;
    &.img-half {
      max-width: 50%;
    }
  }

  ul {
    margin: 20px 0 20px 0;
    padding: 0;
    list-style: none;
    padding-left: 42px;
    margin-top: 20px;
    li {
      position: relative;
      margin-bottom: 5px;
      &::before {
        content: "";
        width: 42px;
        margin-left: -42px;
        position: absolute;
        left: 0;
        top: 6px;
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background-color: var(--color-dark-green);
      }
    }
  }

  ol {
    list-style: none;
    padding-left: 42px;
    margin: 20px 0 20px;
    li {
      counter-increment: li;
      margin-bottom: 5px;
    }
    li::before {
      content: counter(li);
      color: var(--color-dark-green);
      display: inline-block;
      width: 42px;
      margin-left: -42px;
    }
    p {
      display: inline;
    }
  }
  table {
    border: none;
    tr:nth-child(odd) {
      background-color: var(--color-green-10);
      border: none;
    }
    td {
      padding: 18px 30px;
      border: none;
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
  }
  .Supportemne & {
    h2 {
      @media screen and (min-width: 1024px) {
        font-size: var(--font-size-header-small);
        line-height: var(--line-height-header-small);
      }
    }
  }

  &.richtext-component-wide{
    color:var(--color-white);
    
    padding-top:20px;
    padding-left:20px;
    padding-right:20px;
    a {
      color: var(--color-orange);
      @media screen and (hover: hover) {
        &:hover {
          color: var(--color-dark-green);
        }
      }
      &.cta-button {
        background-color: var(--color-orange);
        color:var(--color-white);
        @media screen and (hover: hover) {
          &:hover {
            background-color: var(--color-green);
            color:var(--color-white)
          }
        }
      }
    }
    &.slender{
      .background-dark-green{
        padding:20px;
        border-radius: 5px;
      }
    }
  }

}

.table-wrapper {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  margin-bottom: 1.5em;
}

.show-swipe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  display: none;
  background: rgb(247, 251, 248);
  border-radius: 100px;
  text-align: center;
  .Supportemne & {
    background: var(--color-dark-green);
  }
  svg {
    width: 80%;
  }
}

.wrap-table + .show-swipe {
  display: block;
  @media screen and (hover: hover) {
    display: none;
  }
}

.support-content {
  .richtext-component {
    color: var(--color-white);
    li::before {
      color: var(--color-white);
    }
    & + .richtext-component {
      margin-top: -40px;
    }
    a {
      color: var(--color-white);
      @media screen and (hover: hover) {
        &:hover {
          color: var(--color-green);
        }
      }
    }
  }
}
.richtext-component.background-dark-green.wide {
  ul {
    li {
      &::before {
        background-color: white;
      }
    }
  }
  ol { 
    li::before {
      color: white;
    }
    p {
      display: inline;
    }
  }

}
