.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: var(--color-green-10);
  padding: 10px;
  img {
    border-radius: 100%;
  }
}
