.border-module {
  hr {
    margin-bottom: 0;
    color: var(--color-green);
  }
}

.support-content {
  .border-module {
    hr {
      color: var(--color-white);
    }
  }
}
