.support-page {
  padding-top: 75px;
  position: relative;
  @media screen and (min-width: 768px) {
    padding-top: 45px;
  }
  @media screen and (min-width: 1024px) {
    padding-top: 43px;
  }
  .support-container {
    position: relative;
    padding-bottom: 20px;
    padding-top: 50px;
    @media screen and (min-width: 1024px) {
      padding-bottom: 40px;
    }
  }
  .green-bg {
    position: absolute;
    top: -15px;
    right: 0;
    width: 100%;
    @media screen and (min-width: 768px) {
      top: -76px;
    }
    @media screen and (min-width: 1024px) {
      width: calc(((100vw - 60px) / 12) * 9);
      right: 0px;
      top: -150px;
    }
    bottom: 0;
    background-color: var(--color-petroleum);
    z-index: 1;
    border-radius: 4px;
  }
  .header {
    position: relative;
    z-index: 2;
    padding: 0 10px;
    h1 {
      color: var(--color-white);
      font-weight: 300;
      &.header-margin {
        margin-bottom: 1.5em;
      }
    }
    .description {
      color: var(--color-green);
      font-size: var(--font-size-header-medium);
      line-height: var(--line-height-medium-header);
      padding-bottom: 0;
      @media screen and (min-width: 1024px) {
        font-size: var(--font-size-header-large);
        line-height: var(--line-height-large-header);
        .SupportArticle & {
          font-size: var(--font-size-header-medium);
          line-height: var(--line-height-medium-header);
        }
      }
    }
  }
  .support-content {
    position: relative;
    z-index: 2;
    padding-bottom: 40px;
    @media screen and (min-width: 1024px) {
      padding-bottom: 80px;
    }

    .module {
      @media screen and (min-width: 1024px) {
        .col-md-offset-1,
        .col-lg-offset-2 {
          margin-left: 33.33333333%;
        }
      }
    }
  }
  .support-back {
    top: 15px;
    left: calc(((100vw - 20px) / 12));
    padding-left: 10px;
    @media screen and (min-width: 768px) {
      top: -35px;
    }

    @media screen and (min-width: 1024px) {
      top: 6px;
      left: 20px;
      padding-left: 0;
    }
    z-index: 5;
    position: absolute;
    @media screen and (min-width: 1024px) {
      background-color: var(--color-white);
    }
    padding-right: 30px;
    border-radius: 40px;
    a {
      font-size: var(--font-size-small-medium);
      font-weight: 700;
      text-decoration: none;
      color: var(--color-white);
      font-weight: 300;
      @media screen and (min-width: 1024px) {
        color: var(--color-petroleum);
      }
      @media screen and (hover: hover) {
        &:hover {
          color: var(--color-green);
          .self-repporting-arrow {
            background-color: var(--color-green-10);
          }
        }
      }
    }
    span {
      display: inline-block;
      line-height: 30px;
      @media screen and (min-width: 1024px) {
        line-height: 48px;
      }
    }
    .self-repporting-arrow {
      float: left;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      line-height: 30px;
      padding-top: 4px;

      @media screen and (min-width: 1024px) {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        line-height: 48px;
      }
      background-color: var(--color-white);
      color: var(--color-dark-green);
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      margin-right: 10px;

      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.2);
      @media screen and (min-width: 1024px) {
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
      }
      background-color: var(--color-white);
      transition: all 0.3s ease-in-out;
      .svg-small-arrow-right {
        width: 10px;
        height: 20px;
      }
      svg {
        stroke: var(--color-green);
      }
    }
  }

  .support-area-text {
    color: var(--color-white);
    opacity: 0.5;
  }

  .support-title {
    color: var(--color-white);
    margin-bottom: 40px;
    h2 {
      border-top: 2px solid var(--color-white);
      padding-top: 30px;
    }
    @media screen and (min-width: 768px) {
      h2 {
        font-size: var(--font-size-header-medium);
        line-height: var(--line-height-medium-header);
      }
    }
    @media screen and (min-width: 1025px) {
      flex-direction: row;
      h2,
      p {
        flex-basis: 40%;
      }
    }
    .make-flex {
      justify-content: space-between;
      padding-top: 20px;
      flex-direction: column;

      h2,
      p {
        flex-basis: 100%;
      }
    }
  }

  .to-top {
    display: none;
    @media screen and (min-width: 1024px) {
      display: flex;
    }
    justify-content: flex-start;
    width: calc(((100vw - 140px) / 12) * 2);
    position: fixed;
    left: 50px;
    bottom: 50px;
    cursor: pointer;
    z-index: 200;
    &.hide {
      visibility: hidden;
      z-index: 1;
    }
    .svg-to-top {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }

  .support-menu {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
      z-index: 400;
      position: absolute;
      top: 150px;
      left: 20px;

      ul {
        margin: 0;
        list-style: none;
        padding: 0;
      }
      li {
        margin-bottom: 10px;
        overflow: hidden;

        a {
          min-height: 30px;
          position: relative;
          max-width: 180px;
          text-decoration: none;
          display: block;
          padding-left: 10px;
          span {
            //position: relative;
            //top: 2px;
            //left: 10px;
            //width: auto;

            font-size: var(--font-size-small);
            line-height: var(--line-height-small);
          }
          &::before {
            content: "";
            height: 100%;
            width: 3px;
            background-color: var(--color-petroleum-10);
            position: absolute;
            top: 0;
            left: 0;
          }
          @media screen and (hover: hover) {
            &:hover {
              &::before {
                background-color: var(--color-green);
              }
            }
          }

          &.active {
            &::before {
              background-color: var(--color-green);
            }
          }
        }
      }
    }
  }

  .breadcrumb-section {
    display: none;
    max-width: 380px;
    @media screen and (min-width: 1407px) {
      max-width: 100%;
    }

    @media screen and (min-width: 1024px) {
      display: block;
      position: absolute;
      top: -112px;
      left: calc(((100vw - 140px) / 12) * 4);
      z-index: 900;
      ul {
        margin-left: 40px;
        li:last-child {
          color: var(--color-white);
        }
      }
    }
  }
}
