.foopicker {
  background: var(--color-white);
  border: 1px solid var(--color-gray);
  border-radius: 3px;
  box-shadow: 0 0 3px var(--color-black-40);
  color: var(--color-black);
  margin-top: 10px;
  width: 245px;
}

.foopicker table {
  width: 100%;
}

.foopicker th,
.foopicker td {
  text-align: center;
}

.foopicker__calendar {
  font-size: var(--font-size-small);
  padding: 5px;
}

.foopicker__day {
  border-radius: 100%;
  cursor: pointer;
  padding: 5px 2px;
  transition: all 0.2s ease-out;
  width: 22px;
  line-height: var(--font-size-small);
}

.foopicker__day:hover {
  background: var(--color-petroleum-30);
  color: var(--color-black);
}

.foopicker__day--today {
  background: var(--color-green-10);
  color: var(--color-black);
}

.foopicker__day--selected {
  background: var(--color-green);
  color: #fff;
}

.foopicker__day--disabled {
  background: var(--color-gray);
  color: var(--color-white);
  cursor: not-allowed;
}

.foopicker__day--disabled:hover {
  background: var(--color-gray);
  color: var(--color-white);
}

.foopicker__week {
  font-size: 1em;
  font-weight: 600;
}

.foopicker__header {
  background: var(--color-green);
  padding: 15px;
  text-align: center;
}

.foopicker__header div {
  display: inline-block;
}

.foopicker__arrow {
  background-repeat: no-repeat;
  cursor: pointer;
  height: 24px;
  outline: 0;
  position: absolute;
  top: 31px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 14px;
}

.foopicker__arrow--next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAYAAACulacQAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN5wAADecBmRSqJgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADWSURBVBiVfY8xSkNBFEXPe0njxGB24R4srM1YjFhMGYjYuRxLMWI7lZ8EDAqKiN8mC7B2BULMiPh/noUYvoW57Tncy5Xx+OZZhFfVauC9/6QRFeEFiMtl6yqlcuMPzPntGLgE2XPufZrS/eYKxhjr2aw8EuEC2HXu67ooii6A/FpmJpPJ7akIJ0CpWvWludEUzLhT1uS/2ifVygtASqnV6fTOzBiCPNZ13g8hzPUHbI3MGIrwkHPbhxDmAG3neudmDMCmi0X3MMadj9VPM7aBpFofNAHAN0NkW9nLdIQRAAAAAElFTkSuQmCC);
  right: 14px;
}

.foopicker__arrow--prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAYAAACulacQAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN5wAADecBmRSqJgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADXSURBVBiVXZAxTsNAFETfmMRWvL4C3GVRIlFElFRYHABxB1+CBlFEFBFsgQJrWTIIQRWq9NyDLrG8FGDkeNqn+TPzxUBVVSVtO7oLgaOoD5xbT9r2YAWcSXypA977VIqfQFPg3phxHgHUdW0g8aBpCGFpzDi31jaRc+/ZbqdS4jgEbjebz9xa2wDI+5e3P3A9n8+uJIUuaq/QUFEch1PgQ+KyLF9viqL4N6grtN3q+fd8WGZZfGGtbQZTkhUwk/SQpqNz9TOcW0/S9PsRdAIs9mD/fRAOfwCC1k8eHoJobwAAAABJRU5ErkJggg==);
  left: 18px;
}

.foopicker__month {
  font-size: 0.875em;
  font-weight: 600;
  text-transform: uppercase;
}
