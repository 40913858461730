.search-component {
  .make-flex {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .result {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    margin-top: 20px;
    p {
      margin: 0;
    }
    span {
      font-weight: 700;
    }
  }
  .cta-pagesearch {
    margin-top: 10px;
    cursor: pointer;
  }
  .result-box {
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    background: transparent;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border-color: transparent;
    border-width: 2px;
    border-style: solid;
    position: relative;
    display: block;
    text-decoration: none;
    padding: 30px;
    display: flex;
    margin-bottom: 20px;
    border: 1px solid var(--color-white);
    @media screen and (hover: hover) {
      &:hover {
        border-color: var(--color-dark-green);
        .result-more {
          text-decoration: underline;
        }
      }
    }
  }

  .result-content {
    width: calc(((100vw - 140px) / 12) * 9);
    margin-left: calc((100vw - 140px) / 12);
    @media screen and (min-width: 1025px) {
      width: calc(((100vw - 140px) / 12) * 6);
    }
  }

  .result-title {
    margin-bottom: 20px;
  }

  .result-more {
    margin-bottom: 0;
  }
  label {
    color: var(--color-petroleum);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    margin-bottom: 8px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    &:focus {
      border-color: var(--color-green);
      outline: none;
    }
  }
}
