.link-list {
  .link-list-header {
    margin-bottom: 20px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 60px;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  .cta {
    margin-top: 24px;
  }
}

.link-box {
  background: transparent;
  /* Shadow */
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: calc(100% - 20px);
  border-color: var(--color-white);
  border-width: 2px;
  border-style: solid;
  position: relative;
  display: block;
  text-decoration: none;
  @media screen and (hover: hover) {
    &:hover {
      border-color: var(--color-green);
    }
  }
}
.link-box-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 30px;
  background-color: var(--color-white);
  border-radius: 4px;
  height: 100%;
}
.arrow-icon-container {
  flex-basis: 80px;
  align-self: flex-start;
}
.arrow-icon {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  background-color: var(--color-green-10);
  text-align: center;
  padding-top: 9px;
  svg {
    stroke: var(--color-green);
    width: 27px;
    height: 27px;
  }

}

.link-text {
  flex-basis: 100%;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  margin: 20px 0;
  text-decoration: none;
  flex-basis: auto;
}
.link-more {
  flex-basis: auto;
  margin-bottom: 0;
  align-self: flex-start;
}
