.step-button {
  align-items: flex-start;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  padding: 30px 30px 40px;
  border-top: 1px solid rgba(14, 71, 68, 0.1);
  &:first-child {
    border-color: transparent;
  }

  .step-number {
    margin-right: 30px;
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
    span {
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.step-text {
  .headline {
    margin-bottom: 0;
    font-weight: bold;
  }
  .text {
    margin-bottom: 0;
  }
  .cta {
    margin-top: 30px;
  }
}

.support-content {
  .step-button {
    background-color: var(--color-white);
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}
