.support-subjects-list {
  h2 {
    margin-bottom: 80px;
  }
  h3,
  .h3 {
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-weight: 700;
    margin: 16px 0 18px;
  }
  .support-subject-list {
    margin-bottom: 80px;
  }
  .support-subject-list-outer {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
  .support-subject-list-inner {
    a:first-child {
      margin-top: 0;
    }
  }
  .open-list {
    color: var(--color-green);
    font-weight: 700;

    cursor: pointer;
    &.is-open {
      margin-top: 20px;
    }
    @media screen and (hover: hover) {
      &:hover {
        color: var(--color-petroleum);
      }
    }
  }
  a {
    display: block;
    margin: 20px 0;
    text-decoration: none;
    @media screen and (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .image-container {
    img {
      max-width: 40px;
    }
  }
}
