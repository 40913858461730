/* @import "./variables.css"; */

html,
body {
  font-family: var(--font-hill);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-body);
  background-color: var(--color-white);
  color: var(--color-petroleum);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Headers */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5em 0;
  font-family: var(--font-hill);
  font-weight: var(--font-weight-bold);

  &[class*="secondary"] {
    font-weight: var(--font-weight-light);
  }
}

h1,
.h1,
.manchet {
  font-size: var(--font-size-header-medium);
  line-height: var(--line-height-medium-header);
  font-weight: 400;
  margin-bottom: 0;

  @media screen and (min-width: 1024px) {
    font-size: var(--font-size-header-large);
    line-height: var(--line-height-large-header);
  }
}

h2,
.h2 {
  font-size: var(--font-size-header-small);
  line-height: var(--line-height-small-header);
  font-weight: 300;

  @media screen and (min-width: 1024px) {
    font-size: var(--font-size-header-medium);
    line-height: var(--line-height-medium-header);
    font-weight: 300;
  }
}

h3,
.h3 {
  font-size: var(--font-size-header-small);
  line-height: var(--line-height-small-header);

  @media screen and (min-width: 1024px) {
    font-size: var(--font-size-header-small);
    line-height: var(--line-height-small-header);
  }
}

blockquote {
  font-size: var(--font-size-header-small);
  line-height: var(--line-height-small-header);
  font-weight: 300;

  @media screen and (min-width: 1024px) {
    font-size: var(--font-size-header-medium);
    line-height: var(--line-height-medium-header);
    font-weight: 300;
  }
  .Supportemne & {
    font-size: var(--font-size-header-small);
    line-height: var(--line-height-small-header);
    font-weight: 300;

    @media screen and (min-width: 1024px) {
      font-size: var(--font-size-header-small);
      line-height: var(--line-height-medium-small);
      font-weight: 300;
    }
  }
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

small,
.small {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  * {
    font-size: var(--font-size-small);
    line-height: var(----line-height-small);
  }
}

a {
  color: currentColor;
}

svg {
  fill: currentColor;
  stroke: currentColor;
}

ul,
ol {
  margin-top: 0;
  padding-left: 1em;
}

address {
  font-style: normal;
}

hr {
  border: none;
  background-color: currentColor;
  color: currentColor;
  height: 1px;
  margin: 0 0 1rem 0;
}

::selection {
  color: var(--color-primary);
  background: var(--color-black);
}
