.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.video-cover {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0.99;
  transition: opacity 0.2s ease-out;
  &.active {
    opacity: 0;
    z-index: 0;
  }
}
.video-play {
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  display: flex;
  width: 146px;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;

  @media screen and (hover: hover) {
    &:hover {
      .play-icon {
        background-color: var(--color-green);
        width: 62px;
        height: 62px;
        left: -1px;
        top: -1px;
        .video-play-icon {
          stroke: var(--color-white);
          fill: none;
        }
      }
    }
  }

  .play-icon {
    width: 60px;
    height: 60px;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
  }
  .play-text {
    margin-right: 30px;
    font-weight: 700;
  }
  .video-play-icon {
    stroke: var(--color-green);
    fill: none;
  }
}
.embed-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  &.active {
    opacity: 0.99;
    z-index: 10;
  }
}
.video-player {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media screen and (--mq-mobile-landscape-up) {
      height: 100%;
      top: 0;
    }
  }
}
