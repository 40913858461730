.job-module {
  table {
    border: none;
    font-size:var(--font-size-small);
    line-height:var(--line-height-small);
    tr:nth-child(odd) {
      background-color: var(--color-green-10);
      border: none;
    }
    th,td {
      padding: 18px 30px;
      border: none;
      text-align:left;
    }
  }
  .no-jobs{
    padding: 18px 30px;
    background-color: var(--color-green-10);
    font-weight:var(--font-weight-bold);
  }
}
