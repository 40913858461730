.check-circle {
  width: 46px;
  height: 45px;
  padding: 13px 0;
  background-color: var(--color-green-10);
  border-radius: 46px;
  flex-basis: 45px;
  position: relative;
  flex-shrink: 0;
  svg {
    width: 100%;
    height: 100%;
  }
  span {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
