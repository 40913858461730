.contact-selection {
  color: (--var-petroleum);
  display:none;
  margin-top:80px;
  &.active{
    display:block;
  }
  .label {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    margin-bottom: 8px;
  }
  .alert-text {
    display: none;
    &.active {
      display: block;
    }
    margin-top: 20px;
  }
}

.support-anchor {
  margin-top: 40px;
  cursor: pointer;
  display: none;
  &.active {
    //display: inline-block;
  }
}

.first-level-support {
  margin-top: 40px;
  @media screen and (min-width: 1024px) {
    margin-top: 0;
  }
}

.support-dropdown {
  border: 1px solid var(--color-gray);
  border-radius: 5px;
  padding: 13px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--color-gray);

  &.active {
    border-color: var(--color-green);
  }
  &.selected {
    color: var(--color-petroleum);
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    width: 10px;
    height: 5px;
  }
}

.outer-support-container {
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.inner-support-content {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 0px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  p {
    border-bottom: 1px solid var(--color-petroleum-30);
    padding: 11px 30px 15px;
    margin-bottom: 0;
    cursor: pointer;
    @media screen and (hover: hover) {
      &:hover {
        background-color: var(--color-petroleum-30);
      }
    }
  }
}

.second-level-support {
  margin-top: 40px;
  display: none;
  &.active {
    display: block;
  }
}

.contact-tab {
  display: none;
  &.active {
    display: block;
  }
  .cta-tab {
    width: 100%;
    cursor: pointer;
    padding: 20px 28px 25px 30px;
    margin-top: 40px;
    color: var(--color-petroleum);
    border: 2px solid var(--color-white);
    @media screen and (min-width: 1024px) {
      margin-top: 80px;
    }
    p {
      margin: 0;
      &.header {
        font-weight: 700;
      }
    }
    &.active {
      background-color: var(--color-dark-green);
      color: var(--color-white);
      border: 2px solid var(--color-dark-green);
      pointer-events: none;
    }
    @media screen and (hover: hover) {
      &:hover {
        background-color: var(--color-white);
        color: var(--color-petroleum);
        border-color: var(--color-dark-green);
      }
    }
  }
}

.contact-list {
  display: none;
  &.active {
    display: block;
  }
}

.contact-tab-section {
  display: none;
  margin-top: 40px;
  @media screen and (min-width: 1024px) {
    margin-top: 80px;
  }
  &.active {
    display: block;
  }
  .headline {
    margin-bottom: 20px;
    p {
      font-size: var(--font-size-header-small);
      line-height: var(--line-height-small-header);
      margin: 0;
      &.header {
        font-weight: 700;
        &.header-phone {
          margin-top: 20px;
          @media screen and (min-width: 1024px) {
            margin-top: 0;
          }
        }
      }
    }
    /* a {
      text-decoration: none;
      @media screen and (hover: hover) {
        pointer-events: none;
      }
    } */
  }
}

.key-map {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  &:first-child {
    margin-top: 40px;
  }
  @media screen and (min-width: 768px) {
    margin-top: 40px;
  }
  @media screen and (min-width: 1024px) {
    &:first-child {
      margin-top: 80px;
    }
    margin-top: 80px;
  }
  .risk {
    position: relative;
    padding-left: 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 4px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
    &.low-risk {
      &::before {
        background-color: var(--color-green);
      }
    }
    &.medium-risk {
      &::before {
        background-color: var(--color-yellow);
      }
    }
    &.high-risk {
      &::before {
        background-color: var(--color-orange);
      }
    }
    &.closed {
      &::before {
        background-color: var(--color-gray);
      }
    }
  }
}
.time-table {
  margin-top: 40px;
  @media screen and (min-width: 1024px) {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    margin-top: 25px;
  }
}
.table-row {
  &:first-child {
    .time-slots {
      display: none;
    }
  }
  @media screen and (min-width: 1024px) {
    display: table-row;
    width: 100%;
    &:first-child {
      .time-slots {
        display: table-cell;
        padding-top: 0;
      }
    }
  }
}
.time-slots {
  width: 100%;
  clear: both;
  background-color: var(--color-green-10);
  @media screen and (min-width: 1024px) {
    background-color: transparent;
    width: 33.33%;
    display: table-cell;
    padding-top: 32px;
  }
}

.time-key {
  width: 20%;
  float: left;
  display: flex;
  @media screen and (min-width: 1024px) {
    width: 16%;
    display: table-cell;
    float: none;
  }
  span {
    min-height: 10px;
    min-width: 10px;
    border-radius: 10px;
    display: inline-block;
    margin: 5px 4px;
  }
}

.time-table-legend {
  .time-key {
    display: contents;
    @media screen and (max-width: 1024px) {
      margin-top: 20px;
    }
  }
}

.fallback-contact,
.area-contact {
  margin-top: 40px;
  display: none;
  @media screen and (min-width: 1024px) {
    margin-top: 80px;
  }
  &.active {
    display: block;
  }
}

.contact-call-section {
  .h2 {
    margin-bottom: 20px;
  }
}

.contact-zip-container {
  display: flex;
  input {
    max-width: 286px;
    height: 50px;
    border: 1px solid #949493;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    padding: 12px 30px 14px 31px;
    display: inline-block;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    z-index: 1;
    margin-right: 10px;
  }
  button {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      background-color: var(--color-gray);
      &:hover {
        background-color: var(--color-gray);
      }
    }
  }
}
