.content-component {
  position: relative;
  .content-information {
    width: calc(((100vw - 40px) / 12) * 10);
    margin-left: calc(((100vw - 40px) / 12));
    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      right: 40px;
      padding: 0 10px;
      width: calc(((100% - 80px) / 12) * 3);
      margin-left: 0;
    }

    & > div {
      color: var(--color-black);
      padding: 30px;
    }
    .news-information-newsletter {
      padding: 0;
    }

    address{
      margin-bottom:20px;
    }



  }

  .content-information-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 700;
    }
    a {
      text-decoration: none;
      float: left;
      margin-left: 10px;
      #svg-twitter,
      #svg-linkedin {
        path.circle {
          transition: fill 0.2s ease-in-out;
        }
      }
      @media screen and (hover: hover) {
        &:hover {
          #svg-twitter,
          #svg-linkedin, #svg-facebook {
            path.circle {
              fill: var(--color-green);
            }
          }
        }
      }
    }
  }
}
