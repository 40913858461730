.accordion-component {
  .closed {
    .vertical {
      transition: all 0.25s ease-in-out;
      transform: rotate(0deg);
    }

    .horizontal {
      transition: all 0.2s ease-in-out;
      transform: rotate(0deg);
    }
  }

  .circle-plus {
    height: 26px;
    width: 26px;
    font-size: 1em;
    transition: all 0.2s ease-in-out;
    &.opened {
      opacity: 1;
      transform: rotate(45deg);
    }
  }

  .circle-plus .horizontal {
    position: absolute;
    background-color: var(--color-green);
    width: 26px;
    height: 2px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .circle-plus .vertical {
    position: absolute;
    background-color: var(--color-green);
    width: 2px;
    height: 26px;
    left: 50%;
    transform: translateX(-50%);
    top: 0%;
  }
}
.accordion-container {
  border-bottom: 1px solid rgba(14, 71, 68, 0.3);
  &.first-accordion {
    border-top: 1px solid rgba(14, 71, 68, 0.3);
  }
}
.accordion-headline {
  cursor: pointer;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: var(--font-size-header-small);
    line-height: var(--line-height-small-header);
    margin-bottom: 0;
  }
  &.open {
    h2 {
      color: var(--color-green);
    }
    @media screen and (hover: hover) {
      &:hover {
        h2 {
          text-decoration: underline;
        }
      }
    }
  }
  @media screen and (hover: hover) {
    &:hover {
      h2 {
        color: var(--color-green);
      }
    }
  }
}
.accordion-element {
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.accordion-content {
  padding: 38px 0 40px;
}

.support-content {
  .accordion-component {
    @media screen and (min-width: 1024px) {
      div[class^="col-"] {
        width: 100%;
      }
    }
    h2 {
      color: var(--color-white);
    }
    .accordion-container {
      border-color: rgba(255, 255, 255, 0.3);
    }
    .circle-plus {
      .horizontal,
      .vertical {
        background-color: var(--color-white);
      }
    }
  }
}
