.grid-sizer,
.grid-item,
.grid__col-sizer {
  width: 100%;
  background-color: var(--color-white);
  @media screen and (min-width: 768px) {
    width: 50%;
    padding: 0 10px;
  }

  @media screen and (min-width: 1024px) {
    width: 33%;
  }

  margin-bottom: 20px;
}
