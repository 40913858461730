/* @import "../../styles/variables.css"; */

.svg-search {
  width: 30px;
  height: 30px;
}

.svg-login {
  width: 30px;
  height: 30px;
}

.svg-arrow {
  width: 20px;
  height: 30px;
}

.svg-small-arrow-left,
.svg-small-arrow-right {
  height: 10px;
  width: 5px;
}

.svg-full-arrow {
  width: 25px;
  height: 20px;
  #svg-full-arrow {
    path {
      stroke: var(--color-green);
    }
  }
}

.svg-linkedin,
.svg-twitter,.svg-facebook {
  width: 30px;
  height: 30px;
}

#svg-twitter,
#svg-linkedin,#svg-facebook {
  transition: all 0.2s ease-in-out;
  path {
    stroke: none;
  }
  path.circle {
    fill: var(--color-dark-green);
  }
}

#svg-house,
.svg-house {
  width: 100%;
  height: 100%;
}

#svg-lock{
  height:80%;
  width:80%;
  path{
    stroke: var(--color-green);
  }
  path#lock-path{
    stroke:none;
  }
}


