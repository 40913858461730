.feature-link-list {
  .col-xs-10 {
    margin-bottom: 20px;
  }
  .link-box-container {
    padding: 0;
  }
  .link-box {
    height: 100%;
    border-radius: 4px;
    border: none;
    position: relative;
    @media screen and (hover: hover) {
      &:hover {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          bottom: 0;
          border: 2px solid var(--color-green);
          border-radius: 4px;
        }
      }
    }
  }
  .link-box-container {
    height: 100%;
    .image-container img {
      border-radius: 4px 4px 0 0;
    }
  }
  .image-container {
    //margin: -2px -2px 0 -2px;
  }
  .link-teaser {
    margin: 0px;
    color: var(--color-gray);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    padding: 20px 20px 0;
  }
  .link-text {
    padding: 20px 20px 0;
    margin: 0;
  }
  .link-more {
    margin: 0px;
    color: var(--color-gray);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    padding: 20px;
  }
}
