.breadcrumb-section {
  ul {
    margin: 0;
    padding: 0;
    color: var(--color-petroleum);
    li {
      display: inline-block;
      a {
        color: var(--color-gray);
        text-decoration: none;
        &::after {
          content: " /";
        }
        @media screen and (hover: hover) {
          &:hover {
            color: var(--color-green);
          }
        }
      }
    }
  }
}

.dark-theme {
  .breadcrumb-section {
    ul {
      li {
        a {
          color: var(--color-petroleum);
          @media screen and (hover: hover) {
            &:hover {
              color: var(--color-green);
            }
          }
        }
      }
    }
  }
}

.light-theme {
  .breadcrumb-section {
    ul {
      li {
        color: var(--color-white);
        a {
          color: var(--color-white);
          @media screen and (hover: hover) {
            &:hover {
              color: var(--color-green);
            }
          }
        }
      }
    }
  }
}
