.quote-component {
  padding-top: 40px;
  @media screen and (min-width: 1024px) {
    padding-top: 0;
  }
  blockquote {
    width: 100%;
    margin: 0;
    border-top: 2px solid var(--color-dark-green);
    border-bottom: 2px solid var(--color-dark-green);
    padding: 40px 0 40px;
    &.center-aligned {
      text-align: center;
      padding: 40px 10% 40px;
    }
    @media screen and (min-width: 1024px) {
      padding: 57px 0 60px;
      &.center-aligned {
        padding: 57px 15% 60px;
        &::before {
          left: 0;
        }
      }
    }
    position: relative;
    &::before {
      content: "\201C";
      position: absolute;
      top: -20px;
      left: 0;
      font-size: 142px;

      @media screen and (min-width: 1024px) {
        line-height: 142px;
        top: 37px;
        left: -102px;
      }
    }
    .Supportemne & {
      @media screen and (min-width: 1024px) {
        &::before {
          font-size: 90px;
          line-height: 100px;
        }
      }
    }
  }
  .glide {
    blockquote {
      border-top: 2px solid var(--color-white);
      border-bottom: 2px solid var(--color-white);
    }
  }
}
.quote-author {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  margin: 20px 0 0;
  color: var(--color-gray);
  .glide & {
    color: var(--color-white);
  }
}

.support-content {
  .quote-component {
    blockquote {
      border-color: rgba(255, 255, 255, 0.3);
      color: var(--color-white);
    }
  }
}
