.some-wall {
    color: white;
    padding-top: 3.9vw;
    padding-bottom: 0.8vw;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
    h2 {
        @media screen and (min-width: 705px) {
            margin-left: -20px;
            padding-left: 0;
        }
        @media screen and (min-width: 1024px) {
            margin-left: 0;
            padding-left: 1.8rem;
            padding-right: 1.8rem;
        }
    }
    .row:nth-child(2) {
        [class*="col-"] { 
            padding: 0;
        }
    
        .col-xs-10 {
            @media screen and (max-width: 1023px) {
                margin-left: 3%;
            }
        }
    }
    
}