.edit-zip-popup {
  .KundesupportSektion &{
    position: relative;
    margin-top: 20px;
    bottom: 0;
    left: 0;
    width:auto;
    .help-popup-container{
    height: 0px;
    position: absolute;
    bottom: 50px;
    }

  }
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  z-index: 900;
  .support-menu &{
    position:relative;
    margin-bottom:50px;
    bottom:0;
    left:0;
    .help-popup-container{
      left: 0px;
      height: 0px;
      position: absolute;
      right: 0px;
      top: 100%;
    }
  }
  .active-menu & {
    z-index: 750;
  }
  p.header{
    padding-left:0;
  }
  p{
    max-width:calc(100% - 60px)
  }

  @media screen and (min-width: 768px) {
    bottom: 150px;
    left: 50px;
    max-width: 387px;
  }

  .help-popup-content {
    padding: 50px 30px 30px;
    position: relative;
  }

  .help-popup-form {
    flex-direction:column;
    
    input {
      max-width: unset;    
    }
    .cta {
      margin-left:0;
      margin-top:20px;
    }
  }
  .error-text{
    font-size:12px;
    color:var(--color-orange);
  }

}

.edit-zip-popup-mobile{
    top: 45px;
    left: calc((100vw / 12) + 40px);
    padding-left: 10px;
    z-index: 5;
    position: absolute;
    padding-right: 30px;
    border-radius: 40px;
    width:calc((100vw / 12) * 9);
    .help-popup-container{
      left: -10px;
      height: 0px;
      position: absolute;
      right: 10px;
      top: 100%;
    }
    .help-popup-toggler {
      background: transparent;
      color: var(--color-white);
      padding:0;
      font-weight:var(--font-weight-normal)
    }
    .active-menu & {
    z-index: 750;
  }
  p.header{
    padding-left:0;
  }
  p{
    
  }

  @media screen and (min-width: 768px) {
    bottom: 150px;
    left: 50px;
    max-width: 387px;
  }

  .help-popup-content {
    padding: 50px 30px 30px;
    position: relative;
  }

  .help-popup-form {
    flex-direction:column;
    
    input {
      max-width: unset;    
    }
    .cta {
      margin-left:0;
      margin-top:20px;
      max-height:100%;
    }
  }
  .error-text{
    font-size:12px;
    color:var(--color-orange);
  }
  @media screen and (min-width:1024px){
    display:none;
  }
}







