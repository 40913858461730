.anchor-menu {
  position: absolute;
  top: 150px;
  left: 5px;
  @media screen and (min-width: 768px) {
    top: 250px;
  }
  @media screen and (min-width: 1024px) {
    left: 50px;
  }
  background-color: transparent;
  z-index: 750;
  .active-menu & {
    z-index: 1;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 10px;
    overflow: hidden;

    a {
      min-height: 30px;
      position: relative;
      padding-left: 10px;
      width: 10px;
      display: block;
      overflow: hidden;
      transition: width 0.2s ease-in-out;
      span {
        position: absolute;
        top: 0px;
        left: 10px;
        font-size: 12px;
        white-space: nowrap;
      }
      &::before {
        content: "";
        height: 100%;
        width: 3px;
        background-color: var(--color-petroleum-10);
        position: absolute;
        top: 0;
        left: 0;
      }
      @media screen and (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        &::before {
          background-color: var(--color-green);
        }
      }
    }
  }
}
