.newslist-component {
  .filters {
    margin: 0 calc(var(--padding-site-mobile) * -2);
    @media screen and (max-width: 767px) {
      overflow-x: scroll;
    }
    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }
  .filter-container {
    position: relative;
    padding: 0 10px;
    @media screen and (min-width: 768px) {
      padding: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      padding-bottom: 22px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 5px;
        height: 1px;
        width: 100%;
        background-color: var(--color-border-gray);
      }
      a {
        text-decoration: none;
        padding: 0 19px;
        position: relative;
        white-space: nowrap;
        color: #000000;
        &::after {
          content: "";
          position: absolute;
          bottom: -23px;
          height: 4px;
          left: 0;
          width: 100%;
          background-color: transparent;
          z-index: 10;
        }
        @media screen and (hover: hover) {
          &:hover {
            color: var(--color-dark-green);
            &::after {
              background-color: var(--color-green);
            }
          }
        }
        &.active {
          color: var(--color-dark-green);
          font-weight: 700;
          &::after {
            background-color: var(--color-green);
          }
        }
      }
    }
  }

  .news-container-col {
    padding: 0;
  }

  .news-search {
    margin-top: 20px;
    position: relative;
    label {
      position: absolute;
      top: 0;
      right: 10px;
      visibility: hidden;
    }
    input[type="text"] {
      height: 50px;
      background: var(--color-white);
      padding: 12px 30px 14px 31px;
      border: 1px solid var(--color-gray);
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      z-index: 10;
    }
    input::placeholder {
      font-size: var(--font-size-body);
    }
    button {
      cursor: pointer;
    }
  }

  .delete-search {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    .delete-icon {
      stroke: var(--color-dark-green);
      fill: var(--color-dark-green);
    }
    &.active {
      display: inline;
    }
    @media screen and (hover: hover) {
      &:hover {
        .delete-icon {
          stroke: var(--color-green);
          fill: var(--color-green);
        }
      }
    }
  }

  .result-text {
    margin: 93px 10px 10px 10px;
    @media screen and (min-width: 768px) {
      margin-top: 10px;
    }
    p {
      margin-bottom: 0;
      font-size: var(--font-size-medium);
      line-height: var(--line-height-medium);
      color: var(--color-petroleum);
      span {
        font-weight: 700;
      }
    }
  }

  .newsletter-signup {
    position: absolute;
    right: 0;
    top: 70px;
    @media screen and (min-width: 768px) {
      top: 0;
    }

    @media screen and (min-width: 1025px) {
      right: -20px;
      top: 0;
    }
  }

  .grid {
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      margin-top: 63px;
    }
  }

  .grid-item,
  .grid-sizer {
    a {
      display: block;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
      text-decoration: none;
      padding-bottom: 30px;
      position: relative;
      @media screen and (hover: hover) {
        &:hover {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            border: 2px solid var(--color-green);
            border-radius: 4px;
          }
        }
      }
    }
    &.highlight-item {
      a {
        background-color: var(--color-dark-green);
        color: var(--color-white);
        border-radius: 4px;

        .grid-item-info,
        .grid-item-date {
          color: var(--color-white);
        }
      }
    }
    .image-container {
      padding-top: 56%;
      overflow: hidden;
      position: relative;
      border-radius: 4px 4px 0 0;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        border-radius: 0;
      }
    }
  }

  .grid-item-info,
  .grid-item-content,
  .grid-item-date {
    padding: 0 21px;
  }

  .grid-item-info,
  .grid-item-date {
    color: rgba(0, 0, 0, 0.5);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
  }

  .grid-item-content {
    font-size: 24px;
    line-height: 32px;
    padding-top: 20px;
    margin-bottom: 0;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
