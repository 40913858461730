/* @import "./variables.css"; */

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  background-color: aqua;
}

html,
body {
  padding: 0;
  margin: 0;
}

body.active-menu,
body.active-popup {
  position: fixed;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

main {
  padding-top: 80px;

  .active-selfservice & {
    padding-top: 110px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 164px;

    .active-selfservice & {
      padding-top: 183px;
    }
  }
  transition: all 0.3s ease-in;
}

.headline-container {
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
}

.page-header.noImage {
  margin-bottom: 40px;
}

.site-wrapper {
  width: 100%;
  max-width: var(--content-max-width);
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  background: #ffffff;
  padding-left: var(--padding-site-mobile);
  padding-right: var(--padding-site-mobile);

  @media (--mq-desktop) {
    padding-left: var(--padding-site-desktop);
    padding-right: var(--padding-site-desktop);
  }
}

.supportemne .site-wrapper {
  max-width: unset;
  margin: 0;
}

section {
  margin: 0 0 40px;
  border-radius: 5px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 80px;
  }
}

.section-padding {
  padding-top: 14.28571vw;
  padding-bottom: 14.28571vw;
  margin: 0 0 30px;
  @media screen and (min-width: 1024px) {
    padding-top: 4.1vw;
    padding-bottom: 4.1vw;
    margin-bottom: 30px;
  }
}

.section-padding + section:not(.section-padding),
.add-margin-bottom + section:not(.section-padding),
.add-margin-bottom + .add-margin-top,
.section-padding + .add-margin-top {
  padding-top: 50px;
}

.section-padding-bottom {
  padding-bottom: 14.28571vw;
  @media screen and (min-width: 1024px) {
    padding-bottom: 4.1vw;
  }
}

.section-shadow {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

.is-relative {
  position: relative;
}

.inner-row {
  padding: 0;
  margin-left: -20px;
  margin-right: -20px;
}

.background-green {
  background-color: var(--color-green);
}
.background-dark-green {
  background-color: var(--color-dark-green);
}
.background-petroleum {
  background-color: var(--color-petroleum);
}
.background-orange {
  background-color: var(--color-orange);
}
.background-yellow {
  background-color: var(--color-yellow);
}
.background-white {
  background-color: #ffffff;
}

.background-petroleum-40 {
  background-color: var(--color-petroleum-4);
}
.background-petroleum-5 {
  background-color: var(--color-petroleum-5);
}

.color-green {
  color: var(--color-green);
}
.color-petroleum {
  color: var(--color-petroleum);
}
.color-dark-green {
  color: var(--color-dark-green);
}
.color-orange {
  color: var(--color-orange);
}
.color-yellow {
  color: var(--color-yellow);
}
.color-white {
  color: var(--color-white);
}

.border-green {
  border-color: var(--color-green);
}
.border-dark-green {
  border-color: var(--color-dark-green);
}
.border-petroleum {
  border-color: var(--color-petroleum);
}
.border-orange {
  border-color: var(--color-orange);
}
.border-yellow {
  background-color: var(--color-yellow);
}
.border-white {
  background-color: var(--color-white);
}

.font-bold {
  font-weight: 700;
}

.make-flex {
  display: flex;
}

.pre-text {
  white-space: pre-line;
}

.pr-0 {
  padding-right: 0;
}

.p-0 {
  padding: 0;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
