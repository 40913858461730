.connection-component {
  .slide-container,
  .connection-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .fieldset-container {
    position: relative;
    overflow: hidden;
  }

  fieldset {
    background-color: var(--color-white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.4s ease-in-out;
  }

  fieldset.left {
    transform: translateX(-100%);
  }
  fieldset.center {
    //transform: translateX(0%);
    z-index: 10;
    opacity: 1;
  }
  fieldset.right {
    opacity: 0;
    //transform: translateX(100%);
  }

  .connection-navigation {
    @media screen and (min-width: 1024px) {
      margin-top: 100px;
    }
  }

  .h2 {
    padding-bottom: 40px;
    @media screen and (min-width: 1024px) {
      width: 33.3333%;
    }
    &.end-step {
      padding-bottom: 0;
      @media screen and (min-width: 1024px) {
        width: 50%;
      }
    }
    &.end-step-description {
      color: var(--color-green);
      @media screen and (min-width: 1024px) {
        width: 50%;
      }
    }
  }

  .input-container {
    @media screen and (min-width: 1024px) {
      width: 33.3333%;
    }
  }
  .connection-svg {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
      width: 55.5555556%;
      max-height: 424px;
    }
    svg {
      height: 100%;
    }
  }

  .field-container {
    margin-top: 20px;
    p {
      color: var(--color-black-40);
    }
    @media screen and (min-width: 768px) {
      margin-top: 40px;
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
  label {
    color: var(--color-petroleum);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    margin-bottom: 8px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    &:focus {
      border-color: var(--color-green);
      outline: none;
    }
    &:invalid {
      border-color: var(--color-orange);
    }
  }

  input[type="radio"] {
    visibility: hidden;
    display: block;
    + .fieldset-radio {
      display: block;
      padding-left: 30px;
      position: relative;
      line-height: 25px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border: 2px solid var(--color-green);
        border-radius: 100%;
      }
    }
    &:checked {
      + span {
        &::before {
          content: "\2022";
          color: var(--color-green);
          font-size: 47px;
          line-height: 7px;
        }
      }
    }
  }

  .error-text {
    color: var(--color-orange);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    display: none;
    &.active {
      display: inline;
    }
  }

  textarea {
    min-height: 315px;
    &::placeholder {
      color: var(--color-gray) !important;
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
    }
  }
  textarea + p {
    margin: 20px 0 40px;
  }

  input[type="file"] {
    visibility: hidden;
    width: 12px;
  }
  .file-container {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    label {
      cursor: pointer;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding: 0 14px;
      }
    }
  }
  .field-city {
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      float: left;
      width: 65%;
      margin-top: 0px;
    }
  }
  .field-zip {
    margin-right: 5%;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      float: left;
      margin-top: 0;
      width: 30%;
    }
  }

  .checkbox-container {
    cursor: pointer;
    input {
      visibility: hidden;
      display: block;
    }
    span {
      display: block;
      padding-left: 44px;
      position: relative;
      line-height: 22px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: 2px solid var(--color-petroleum-50);
        border-radius: 4px;
      }
    }

    input:checked + span::before {
      content: "\2713";
      color: var(--color-green);
      border-color: var(--color-green);
      text-align: center;
      font-size: 20px;
      line-height: 18px;
    }
    input:invalid + span::before {
      border-color: var(--color-orange);
    }
  }

  .cta-send {
    margin-top: 0px;
    float: right;
    cursor: pointer;
    @media screen and (min-width: 1024px) {
      margin-top: 0px;
    }
    &.inactive {
      background-color: var(--color-gray);
      cursor: not-allowed;
      @media screen and (hover: hover) {
        &:hover {
          background-color: var(--color-gray);
        }
      }
    }
  }

  .step-back {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: var(--color-green-10);
    }
    svg {
      align-self: center;
    }
  }

  .connection-steps {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
    }
    ul {
      list-style: none;
      margin: 13px 0 0 0;
      padding: 0;
      height: auto;
      li {
        display: inline-block;
        width: 15px;
        height: 3px;
        background-color: var(--color-green-10);
        margin-right: 5px;
        &:last-child() {
          margin-right: 0;
        }
        &.active {
          background-color: var(--color-green);
        }
      }
    }
  }
  .dot {
    background-color: var(--color-dark-green);
    width: 14px;
    height: 14px;
    border-radius: 100%;
    display: block;
    margin-bottom: 11px;
  }

  .data-table {
    @media screen and (min-width: 1024px) {
      display: table;
      table-layout: fixed;
      border-spacing: 0;
      margin-bottom: 20px;
    }
  }

  .data-row {
    &:nth-child(odd) {
      background-color: var(--color-petroleum-5);
    }
    @media screen and (min-width: 1024px) {
      display: table-row;
    }
  }
  .data-col {
    .edit {
      color: var(--color-green);
      cursor: pointer;
      padding: 0 var(--grid-gutter);
      margin-bottom: 1.5em;
      @media screen and (min-width: 1024px) {
        padding: 0;
      }

      @media screen and (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:first-child {
      font-weight: var(--font-weight-bold);
    }
    &:last-child {
      padding-bottom: 1.5em;
      @media screen and (min-width: 1024px) {
        padding-bottom: 0;
      }
    }
    @media screen and (min-width: 1025px) {
      padding: 18px 20px;
      display: table-cell;
      width: 50%;
      &:first-child {
        width: 25%;
      }
      &:last-child {
        width: 25%;
      }
    }
  }
  .data-col-label {
    padding-top: 1.5em;
    @media screen and (min-width: 1024px) {
      padding-top: 0;
    }
  }

  .data-text {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0px;
    }
  }
  .data-text-container {
    @media screen and (min-width: 1024px) {
      max-width: 50%;
    }
    p {
      font-size: var(--font-size-medium);
      line-height: var(--line-height-medium);
    }
  }
  .floorplan-svg {
    path.layout-stroke {
      stroke: #e6e6e6;
    }
    &.active {
      path.layout-stroke {
        stroke: var(--color-green);
      }
    }
  }
}
