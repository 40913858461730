.search-result-item.cta {
  
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid var(--color-white);
  width: 100%;
  padding-top: 30px;
  @media screen and (hover: hover) {
    &:hover {
      color: var(--color-petroleum);
      border-color: var(--color-dark-green);
      background-color: transparent;
    }
  }
  .arrow-container {
    background-color: var(--color-green-10);
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    margin-bottom: 10px;

    .svg-full-arrow {
      align-self: center;
      margin: 0 auto;
    }
  }
}

.search-result-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .h3 {
    margin-bottom: 20px;
  }
  .search-result-link {
    font-weight: 700;
    margin-bottom: 0;
  }
}
