.featurepage-header {
  margin-top: -50px;
  margin-bottom: -50px;
  margin-left: calc(var(--padding-site) * -1);
  margin-right: calc(var(--padding-site) * -1);

  @media screen and (min-width: 1024px) {
    margin-bottom: 50px;
    margin-left: calc(var(--padding-site-desktop) * -1);
    margin-right: calc(var(--padding-site-desktop) * -1);
  }

  padding-top: 50px;
  background-size: cover;
  min-height: 75vh;
  &.aspect-video {
    min-height: 56.24%;
    aspect-ratio: 16 / 9;
    margin-bottom: 23px;
    margin-top: 0;
    @media screen and (min-width: 1024px) {
      min-height: 75vh;
      aspect-ratio: unset;
      margin-bottom: 50px;
      margin-top: -50px;
    }
  }
  &.dark-theme {
    color: var(--color-petroleum);
    .cta {
      margin-top: var(--padding-site-desktop);
      background-color: var(--color-dark-green);
      @media screen and (hover: hover) {
        &:hover {
          background-color: var(--color-green);
        }
      }
    }
  }
  &.light-theme {
    color: var(--color-white);
    .cta {
      margin-top: var(--padding-site-desktop);
      background-color: var(--color-white);
      color: var(--color-petroleum);
      @media screen and (hover: hover) {
        &:hover {
          background-color: var(--color-green);
          color: var(--color-white);
        }
      }
    }
  }
  &.light-theme {
    color: var(--color-white);
  }
  .page-header {
    margin-bottom: 50px;

    h1 {
      margin-bottom: 50px;
      color: inherit;
      @media screen and (min-width: 1024px) {
        margin-bottom: 50px;
      }
    }
    .manchet {
      color: inherit;
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
    }
  }
}
