.news-container {
  position: relative;

  .news-information {
    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      right: 40px;
      padding: 0 10px;
      width: calc(((100% - 80px) / 12) * 4);
    }
    @media screen and (min-width: 1200px) {
      width: calc(((100% - 80px) / 12) * 3);
    }
    & > div {
      color: var(--color-black);
      padding: 30px;
      margin-top: 20px;
    }
    .news-information-newsletter {
      padding: 0;
    }
  }
}

.news-information-contact {
  color: var(--color-black);
  padding: 30px;
  p {
    margin-bottom: 0;
  }
  a {
    display: block;
    text-decoration: none;
    @media screen and (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .name {
    font-weight: 700;
  }
  .bottom-content {
    margin-top: 24px;
  }
}

.news-information-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 700;
  }
  a {
    text-decoration: none;
    float: left;
    margin-left: 10px;
    @media screen and (hover: hover) {
      &:hover {
        #svg-twitter,
        #svg-linkedin,
        #svg-facebook {
          path.circle {
            fill: var(--color-green);
          }
        }
      }
    }
  }
}

.news-information-newsletter {
  .cta {
    display: block;
  }
}

.news-information-download {
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    @media screen and (hover: hover) {
      &:hover {
        .download-icon {
          background-color: var(--color-dark-green);
          svg {
            path {
              stroke: var(--color-white);
            }
          }
        }
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .header {
    font-weight: 700;
  }
  .download-icon {
    background-color: var(--color-green-10);
    width: 40px;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    flex-shrink: 0;
    svg {
      fill: none;
      width: 20px;
      height: 40px;
      path {
        stroke: var(--color-dark-green);
      }
    }
  }
  .download-container {
    max-width: calc(100% - 50px);
  }
}

.latest-news{
  .fp-herocontainer{
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-start;
    padding-bottom:30px;
    padding-top:30px;
    p.h2 {
      color: var(--color-petroleum);
      z-index: 400;
      position: relative;
      margin-bottom: 0;
      padding-bottom:15px;      
    }
    p {
      margin-bottom: 0;
      z-index: 400;
      position: relative;
      
    }
    .cta{
      margin-top:15px;
      background:var(--color-dark-green);
      &:hover{
        background:var(--color-green);
      }
    }
  }
}
