.zipPopUp-module {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-petroleum-85);
  display: none;
  z-index: 900;
  &.show-popup {
    display: flex;
    align-items: center;
  }
}

.zipPopUp {
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 4px;
  @media screen and (min-width: 1024px) {
    padding: 100px 108px;
  }
  .header {
    font-weight: 700;
    margin: 31px 0 0;
  }
  a {
    display: inline-block;
    margin-top: 40px;
    font-weight: 700;
    text-decoration: none;
    @media screen and (hover: hover) {
      &:hover {
        color: var(--color-green);
      }
    }
  }
  .error-text {
    display: none;
    &.activate {
      display: block;
    }
    p {
      margin-bottom: 0;
      margin-top: 20px;
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
      color: var(--color-orange);
    }
  }
}
