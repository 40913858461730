/* @import "./variables.css"; */

/* flexboxgrid overrides */

.container-fluid {
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media (--mq-desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.row {
  margin-right: 0;
  margin-left: 0;
  max-width: var(--content-max-width);
  margin: auto;
  @media all and (-ms-high-contrast: none) {
    width: 100%;
  }
}

[class*="col-"] {
  padding-right: var(--grid-gutter);
  padding-left: var(--grid-gutter);
}
