.alert-component {
  margin: 20px 0 0;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 0;
  &.hide-alert {
    height: 0;
    margin: 0;
  }
  .alert-component-container {
    padding: 45px 30px 30px;
    @media screen and (min-width: 768px) {
      padding: 30px 30px;
    }
  }
  p {
    color: var(--color-white);
    margin-bottom: 0;
    @media screen and (min-width: 678px) {
      max-width: 80%;
    }
    &.header {
      font-weight: 700;
    }
  }

  .cta {
    margin-top: 20px;
    color: var(--color-orange);
    @media screen and (hover: hover) {
      &:hover {
        color: #ffffff;
      }
    }
  }

  .close-container {
    top: 20px;
    right: 30px;
    label {
      color: var(--color-white);
    }
    .close {
      top: 0;
      left: -40px;
      margin-top: 0;
      color: var(--color-white);
    }
    @media screen and (hover: hover) {
      &:hover {
        .leftright,
        .rightleft {
          background-color: var(--color-dark-green);
        }
        .close {
          opacity: 1;
          color: var(--color-dark-green);
        }
      }
    }
  }
}
