/* @import "../../styles/variables.css"; */

footer {
  align-self: flex-end;
  min-height: 100px;
  padding-top: 54px;
  padding-bottom: 75px;
  width: 100%;
  border-radius: 5px;
  color: var(--color-white);
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    margin-bottom: 30px;
  }
  a {
    text-decoration: none;
    @media screen and (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .make-flex {
    align-items: flex-end;
  }
}
.footer-header {
  font-size: var(--font-size-header-medium);
  line-height: var(--line-height-medium-header);
}

.footer-list-header {
  font-weight: 700;
  margin-bottom: 10px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
}

.footer-center-row {
  margin-top: 28px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 43px;
  }
  li {
    margin-bottom: 10px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
    a {
      display: block;
      position: relative;
      text-decoration: none;
      @media screen and (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.footer-bottom-row {
  margin-top: 20px;
  @media screen and (min-width: 1024px) {
    margin-top: 60px;
  }
  p {
    align-self: flex-end;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  a {
    align-self: flex-end;
  }
  ul {
    margin: 0 0 20px 0;
    @media screen and (min-width: 768px) {
      margin: 0;
    }
    padding: 0;
    list-style: none;
    li {
      a {
        display: block;
      }
    }
  }
  .some-link {
    margin-right: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: var(--color-dark-green);
    border-radius: 50%;
    justify-content: center;
    transition: opacity 350ms ease-out;

  

    .svg-icon{
      height: 15px;
      width: 14px;
    }

    &:hover{
      opacity: 0.6;
    }

  }
}
.footer-center-row {
  .footer-logo {
    margin-bottom: 52px;
    display: inline-block;
    svg{
      stroke:none;
      width:113px;
      height:auto;      
    }
    
  }
   
}
