/**
 * @license
 * MyFonts Webfont Build ID 3822505, 2019-10-11T06:57:25-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FibraOneAlt-Bold by Los Andes
 * URL: https://www.myfonts.com/fonts/los-andes/fibra-one/alt-bold/
 * Licensed pageviews: 1,000,000
 * 
 * Webfont: FibraOneAlt-Light by Los Andes
 * URL: https://www.myfonts.com/fonts/los-andes/fibra-one/alt-light/
 * Licensed pageviews: 10,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3822505
 * Webfonts copyright: Jorge Cisterna
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a53a9");

@font-face {
  font-family: "FibraOneAlt";
  src: url("/assets/fonts/3A53A9_0_0.eot");
  src: url("/assets/fonts/3A53A9_0_0.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/3A53A9_0_0.woff2") format("woff2"),
    url("/assets/fonts/3A53A9_0_0.woff") format("woff"),
    url("/assets/fonts/3A53A9_0_0.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FibraOneAlt";
  src: url("/assets/fonts/3A53A9_1_0.eot");
  src: url("/assets/fonts/3A53A9_1_0.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/3A53A9_1_0.woff2") format("woff2"),
    url("/assets/fonts/3A53A9_1_0.woff") format("woff"),
    url("/assets/fonts/3A53A9_1_0.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
