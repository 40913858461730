.image-container,
.picture-container {
  position: relative;
  border-radius: 4px;
  max-width: 100%;
  flex-basis: auto;
  flex-shrink: 0;
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 4px;
    &[data-img] {
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }
    &.lzy_img {
      opacity: 0.99;
    }
  }
  &.no-filter {
    &::before {
      content: none;
    }
  }
  &.no-width {
    img {
      width: auto;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    #evida &{
      background-color: var(----color-petroleum-4);
    }
    border-radius: 3px;
    z-index: 10;
  }
  &.no-filter::before {
    content: none;
  }
}

.picture-container {
  margin-top: 40px;
  @media screen and (min-width: 1024px) {
    margin-top: 0;
  }
}

.image-component {
  .caption {
    margin: 20px 0 0;
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    color: var(--color-gray);
  }
}

.image-text {
  margin: 10px 0 0;
  color: var(--color-gray);
}
