.hero {
  .video-container{
    margin-top:30px;
    @media (--mq-tablet){
      margin-top:0px;
    }
  }

  &.background-gasfakta {
    background-color: #26484a;
    color: var(--color-white);
  }

  &.background-dgc {
    background-color: #134944;
    color: var(--color-white);
  }

  &.background-dark-green {
    background-color: #267b38;
    color: var(--color-white);
    .flex-cta {
      background-color: var(--color-white);
      color: var(--color-petroleum);
      @media screen and (hover: hover) {
        &:hover {
          background-color: #2ea149;
          color: var(--color-white);
        }
      }
    } 
  }

  &.background-petroleum {
    background-color: #0e4744;
    color: var(--color-white);
  }

  .make-flex {
    flex-direction: column;
    align-items: baseline;
    height: 100%;
    width: 100%;
  }
  .hero-tag {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    flex-basis: 50%;
    width: 100%;
  }
  .hero-text {
    font-size: var(--font-size-header-medium);
    line-height: var(--line-height-medium-header);
    flex-basis: 100%;
    width: 100%;
  }
  .flex-text {
    margin-bottom: 40px;
    
  }
  .flex-cta {
    color: var(--color-white);
    background-color: var(--color-dark-green);
    flex-basis: auto;
    align-self: flex-start;
    @media screen and (hover: hover) {
      &:hover {
        background-color: var(--color-green);
      }
    }
    @media screen and (min-width:1024px){
      margin-top:40px;
    }
  }
  .image-container {
    margin-bottom: 20px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
  .play-text{
    color:var(--color-dark-green);
  }
}
