.paging {
  display: flex;
  justify-content: center;
  width: 100%;
}

.paging-item {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  @media screen and (min-width: 768px) {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    line-height: 48px;
  }
  background-color: var(--color-green-10);
  color: var(--color-dark-green);
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  margin-left: 10px;
  margin-top: 80px;
  transition: all 0.3s ease-in-out;
  svg {
    stroke: var(--color-green);
  }

  &.active {
    background-color: var(--color-green);
    color: var(--color-white);
  }
  @media screen and (hover: hover) {
    &:hover {
      background-color: var(--color-green);
      color: var(--color-white);
      svg {
        stroke: var(--color-white);
      }
    }
  }
}

.paging-forward,
.paging-back {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 768px) {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  }
  background-color: var(--color-white);
}
