.contact-module {
  padding-top: 14.28571vw;
  padding-bottom: 14.28571vw;
  @media screen and (min-width: 1024px) {
    padding-top: 7.1vw;
    padding-bottom: 7.1vw;
  }

  .flex-text {
    flex-basis: auto;
    margin-bottom: 0;
    width: 100%;
  }
  .flex-subtext {
    flex-basis: 100%;
    font-size: var(--font-size-header-medium);
    line-height: var(--line-height-medium-header);
    width: 100%;
    padding-bottom: 20px;
  }
  .flex-cta {
    align-self: flex-start;
  }
  .circle-image {
    margin-bottom: 20px;
    float: right;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
      float: none;
    }
  }
}
