.flex-container {
  flex-direction: column;
  //align-items: baseline;
  height: 100%;
  width: 100%;
  display: flex;

  .flex-tag {
    flex-basis: 20%;
    width: 100%;
  }
  .flex-text {
    font-size: var(--font-size-header-medium);
    line-height: var(--line-height-medium-header);
    width: 100%;
  }
  .flex-cta {
    color: var(--color-white);
    background-color: var(--color-dark-green);
    flex-basis: auto;
    @media all and (-ms-high-contrast: none) {
      margin-top: 80px;
    }
    @media screen and (hover: hover) {
      &:hover {
        background-color: var(--color-green);
        color: var(--color-white);
      }
    }
  }
}
