.tab-module {
  h2 {
    margin-bottom: 40px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 80px;
    }
  }
}
.tab-module-container {
  position: relative;
  @media screen and (min-width: 1024px) {
    padding: 0;
  }
}

.tab-module-cta {
  margin-top: 80px;
  @media screen and (min-width: 1024px) {
    margin-left: calc(((100vw - 140px) / 12) * 2);
  }
}

.tab-content {
  padding-bottom: 20px;
  color: var(--color-black);
  a {
    color: var(--color-dark-green);
  }
  .cta {
    font-weight: 700;
    margin-top: 56px;
    color: var(--color-white);
  }
}

.tab-button {
  background: var(--color-white);
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 22px;
  margin-bottom: 20px;
  cursor: pointer;
  border-color: var(--color-white);
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  &.active {
    background-color: var(--color-dark-green);
    border-color: var(--color-dark-green);
    .tab-check {
      background-color: var(--color-petroleum);
      text-align: center;
      svg {
        stroke: var(--color-white);
      }
      span {
        color: var(--color-white);
      }
    }
    .button-text {
      color: var(--color-white);
    }
  }
  @media screen and (hover: hover) {
    &:hover {
      border-color: var(--color-dark-green);
    }
  }
  @media screen and (min-width: 1024px) {
    width: calc(33.33333333% - 20px);
    margin-left: calc((((100vw - 140px) / 12) * 2) + 10px);
  }

  .button-text {
    flex-basis: auto;
    margin-left: 30px;
    font-weight: 700;
  }
}

.tab-container {
  line-height: 24px;
  background-color: var(--color-white);
  display: none;
  @media screen and (min-width: 1024px) {
    display: inline;
    z-index: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &.active {
    display: inline;
    @media screen and (min-width: 1024px) {
      z-index: 1;
      opacity: 0.99;
    }
  }
  .image-container {
    margin-bottom: 38px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 76px;
    }
  }

  @media screen and (min-width: 1024px) {
    width: calc(33.33333333%);
    //width: calc(45%);
    position: absolute;
    //right: 0;
    left: calc(100% / 12 * 7);
    top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}
