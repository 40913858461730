.fp-header {
  position: relative;
  @media screen and (min-width: 678px) {
    min-height: 483px;
    padding:0;
    .row{
      max-width:100vw;

    }
  }

  .fp-outer{
    display:flex;
    align-items:flex-end;
    box-shadow: 2px 2px 17px 8px rgb(0 0 0 / 18%);
    flex-wrap:wrap;
    @media screen and (min-width: 1400px) {
      flex-wrap:nowrap;
    }

  }

  .image-container {
    flex-basis:100%;
    @media screen and (min-width: 1400px) {
      margin-top: 20px;
      margin-top: 0;
      flex-basis:70%;
    }
  }

  .svg-container {
    width: calc(((100vw - 40) / 12) * 10);
    margin-top: 40px;
    @media all and (-ms-high-contrast: none) and (min-width: 1400px) {
      top: 0;
      max-width: 100%;
      overflow: hidden;
    }
    @media screen and (min-width: 1400px) {
      bottom: 50px;
      display: block;
      width: 50vw;
      //max-height: 100%;
      position: absolute;
      right: 50px;
      margin: 0;
    }
  }
  h1 {
    color: var(--color-petroleum);
    z-index: 400;
    position: relative;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    z-index: 400;
    position: relative;      
  }
  .fp-herocontainer{
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-start;
    padding: 10px 10px 30px;
    @media screen and (min-width: 1400px) {
      padding: 0 30px 30px;
    }
    h1 {
      color: var(--color-petroleum);
      z-index: 400;
      position: relative;
      margin-bottom: 0;
      padding-bottom:30px;      
    }
    p {
      margin-bottom: 0;
      z-index: 400;
      position: relative;
      
    }
    .cta{
      margin-top:15px;
      background:var(--color-dark-green);
      &:hover{
        background:var(--color-green);
      }
    }
  }
  .video-outer{
    width:100%;
    @media screen and (min-width: 1400px) {
      flex-basis:70%;
    }
  }

}

.forside{
  .fp-header{
    .fp-outer {
      box-shadow: none;
    }
  } 
}

.fp-grid{
  display: grid;
  grid: 1fr  / repeat(16, 1fr);
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  .fp-image-container{
    grid-column: 1 / span 16;
    .image-container{
      height: 100%;
      img{
        height:100%;
        object-fit: cover;
      }
    } 
  }
  
  .fp-content-container{
    display: flex;
    align-items: center;
    grid-column: 1 / span 16;
    padding:30px 15px;
    
    p.fp-teaser-description{
      margin:30px 0;
      @media (--mq-tablet){
        margin:60px 0;
      }
    } 
    .cta{
      color:var(--color-white);
      background-color: var(--color-dark-green);
      &:hover{
        background-color: var(--color-green);
      }
    }
  }

  &.image-left{
    .fp-image-container{
      @media (--mq-tablet){
          grid-column: 1 / span 8;      
      }      
    }
    .fp-content-container{
      @media (--mq-tablet){
        grid-column: 10 /span 5;
        grid-row:1 / 1
      }
    }
  }

  &.image-right{
    .fp-image-container{
      @media (--mq-tablet){      
        grid-column: 9 / span 8;        
      }
    }
    .fp-content-container{
      @media (--mq-tablet){
        grid-column: 3 /span 5;
        grid-row:1 / 1
      }
    }
  }

}