/* @import "./variables.css"; */

.cta {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #ffffff;
  text-decoration: none;
  padding: 13px 30px;
  display: inline-block;
  border-color: transparent;
  transition: all 0.2s ease-in-out;
  @media screen and (hover: hover) {
    &:hover {
      background-color: var(--color-dark-green);
      color: #ffffff;
    }
  }
  @media screen and (hover: hover) {
    &.background-dark-green {
      &:hover {
        background-color: var(--color-green);
      }
    }
  }
}

.button {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: var(--font-hill);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-demibold);
  line-height: var(--font-lineheight-headers);
  border: 1px solid var(--color-black);
  border-radius: 1rem;
  padding: 3px 1rem 0 1rem;
  height: 2rem;

  &.secondary {
    background-color: transparent;
    border: 1px solid currentColor;
    color: currentColor;
  }

  svg + span {
    margin-left: 0.5rem;
  }

  span + svg {
    margin-left: 0.5rem;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;

  .button {
    margin-bottom: 1rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
