.contact-form-section {
  .field-container {
    margin-top: 20px;

    @media screen and (min-width: 768px) {
      margin-top: 40px;
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
    &:first-child {
      margin-top: 0;
    }
    .alert-text {
      display: none;
    }
    &.subject-container {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  label {
    color: var(--color-petroleum);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    margin-bottom: 8px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    &:focus {
      border-color: var(--color-green);
      outline: none;
    }
    &:invalid {
      border-color: var(--color-orange);
    }
  }
  .error-text {
    color: var(--color-orange);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    display: none;
    &.active {
      display: inline;
    }
  }

  textarea {
    min-height: 315px;
    &::placeholder {
      color: var(--color-gray) !important;
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
    }
  }
  textarea + p {
    margin: 20px 0 40px;
  }

  input[type="file"] {
    visibility: hidden;
    width: 12px;
  }
  .file-container {
    border: 1px solid var(--color-gray);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    color: var(--color-petroleum);
    padding: 12px 30px 14px 31px;
    border-radius: 5px;
    width: 100%;
    label {
      cursor: pointer;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding: 0 14px;
      }
    }
  }
  .field-city {
    @media screen and (min-width: 768px) {
      float: left;
      width: 65%;
      margin-right: 5%;
    }
  }
  .field-zip {
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      float: left;
      margin-top: 0;
      width: 30%;
    }
  }

  .checkbox-container {
    cursor: pointer;
    input {
      visibility: hidden;
      display: block;
    }
    span {
      display: block;
      padding-left: 44px;
      position: relative;
      line-height: 22px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: 2px solid var(--color-petroleum-50);
        border-radius: 4px;
      }
    }

    input:checked + span::before {
      content: "\2713";
      color: var(--color-green);
      border-color: var(--color-green);
      text-align: center;
      font-size: 20px;
      line-height: 18px;
    }
    input:invalid + span::before {
      border-color: var(--color-orange);
    }
  }

  .cta-send {
    cursor: pointer;
    margin-top: 40px;
    float: right;
    @media screen and (min-width: 1024px) {
      margin-top: 80px;
    }
    &.inactive {
      background-color: var(--color-gray);
      cursor: not-allowed;
      @media screen and (hover: hover) {
        &:hover {
          background-color: var(--color-gray);
        }
      }
    }
    &.hidden {
      display: none;
    }
  }

  .upload-files {
    &.active {
      margin-top: 20px;
    }
    p {
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
      color: var(--color-petroleum-30);
      margin-bottom: 0;
    }
  }
}

.contact-form-row,
.contact-receipt-row {
  position: relative;
  display: none;
  &.active {
    display: flex;
  }
}

.post-error-module {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-petroleum-5);
  display: none;
  z-index: 900;
  &.show-popup {
    display: flex;
    align-items: center;
  }
}

.post-error {
  background-color: var(--color-white);
  padding: 20px;
  @media screen and (min-width: 1024px) {
    padding: 100px 108px;
  }
  .header {
    font-weight: 700;
    margin: 31px 0 0;
  }
  a {
    display: inline-block;
    margin-top: 40px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      color: var(--color-green);
    }
  }
  .error-text {
    display: none;
    &.activate {
      display: block;
    }
    p {
      margin-bottom: 0;
      margin-top: 20px;
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
      color: var(--color-orange);
    }
  }
}

.form-spinner-overlay {
  display: none;
  cursor: wait;
  margin-top: 40px;
  @media screen and (min-width: 1024px) {
    margin-top: 80px;
  }
  float: right;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #ffffff;
  text-decoration: none;
  padding: 13px 30px;
  border-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &.active {
    display: inline-block;
  }
}

.loader {
  margin: 9px 20px;
  font-size: 7px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
