.help-popup {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  z-index: 900;
  .active-menu & {
    z-index: 750;
  }
  @media screen and (min-width: 768px) {
    bottom: 50px;
    left: 50px;
    max-width: 387px;
  }

  hr {
    margin-top: 31px;
    color: var(--color-petroleum);
    opacity: 0.1;
  }
}

.help-popup-container {
  background: var(--color-white);
  border-radius: 4px;
  margin-bottom: 11px;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &.closed {
    width: 0;
  }
}

.help-popup-content {
  padding: 100px 30px 30px;
  position: relative;
  .error-text {
    display: none;
    &.activate {
      display: block;
    }
    p {
      margin-bottom: 0;
      margin-top: 20px;
      font-size: var(--font-size-medium);
      line-height: var(--line-height-medium);
      color: var(--color-orange);
    }
  }
  .leftright,
  .rightleft {
    background-color: var(--color-green);
  }
  .close-container {
    label {
      color: var(--color-petroleum);
    }
    .close {
      top: 0;
      left: -40px;
      margin-top: 0;
    }
    @media screen and (hover: hover) {
      &:hover {
        .leftright,
        .rightleft {
          background-color: var(--color-dark-green);
        }
        .close {
          opacity: 1;
        }
      }
    }
  }
  .circle-image {
    margin-bottom: 24px;
    width: 80px;
    height: 80px;
  }
  p {
    margin-bottom: 0;
  }
  p.header {
    font-weight: 700;
  }
}

.help-popup-text {
  margin-bottom: 26px;
}

.help-popup-form {
  display: flex;
  position: relative;
  label {
    position: absolute;
    z-index: 0;
    @media all and (-ms-high-contrast: none) {
      opacity: 0;
    }
  }
  input {
    max-width: 186px;
    height: 50px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    padding: 12px 30px 14px 31px;
    display: inline-block;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    z-index: 1;
    &::placeholder {
      color: var(--color-gray);
    }
  }
  .cta {
    max-height: 50px;
    margin-left: 15px;
    cursor: pointer;
    &[disabled] {
      background-color: var(--color-gray);
      cursor: wait;
    }
  }
}

.help-popup-emergency {
  a {
    color: var(--color-dark-green);
  }
}

.help-popup-toggler {
  background: var(--color-white);
  color: var(--color-petroleum);
  font-weight: 700;
  width: 100%;
  border-radius: 0;
  @media screen and (min-width: 768px) {
    width: auto;
    border-radius: 4px;
  }
  @media screen and (hover: hover) {
    &:hover {
      color: var(--color-white);
      background-color: var(--color-green);
      span {
        color: var(--color-white);
      }
    }
  }
  span {
    color: var(--color-gray);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    font-weight: 300;
    padding-left: 10px;
    display: none;
  }
  &.hasZip {
    span {
      display: inline;
    }
  }
}
