.contact-page-top{
  padding-bottom:0;
  margin-bottom:20px;

  .contact-page-top-address{
    display:flex;
    align-items:center;
    gap:10px;
    margin-bottom:25px;
    @media screen and (min-width:1024px){
      flex-direction:column;
      gap:20px;
      margin-bottom:0;
    }
    .circle-image{
      flex:0 0 100px;
    }
  }

  .contact-address{
    font-size:var(--font-size-medium);
    line-height:var(--line-height-medium);
    flex:1 1 auto;
  }
  .contact-selectbox-container{
    display:flex;
    justify-content:space-evenly;
    padding: 10px 0;
    gap:10px;
     @media screen and (min-width:1024px){
      gap:0px;
    }
    flex-wrap:wrap;
  }
  .contact-selectbox{
    box-shadow: 0 4px 34px rgb(0 0 0 / 18%);
    flex:0 1 125px;
    @media screen and (min-width:1024px){
      flex:0 1 200px;
    }
    display:flex;
    flex-direction:column;
    border-radius:10px;
    padding:13px 0; 
    background:var(--color-white);
    cursor:pointer;
    border:2px solid var(--color-white);
    transition:all 0.2s ease;
   
    &:hover{
      border-color:var(--color-green);
    }
    svg{
      align-self:center;
      max-width:41px;
      max-height:41px;
      margin:0 0 7px;
      @media screen and (min-width:1024px){
        max-width:82px;
        max-height:82px;
        margin:0 0 13px;
      }
    }
    p{
      font-size:var(--font-size-small);
      @media screen and (min-width:1024px){
        font-size:var(--font-size-body);
      }
      text-align:center;
      margin-top:auto;
      font-weight:700;
      line-height:1.2rem;
      margin-bottom:0;
    }
    &.active{
      border-color:var(--color-green);
    }
  }
}