.close-container {
  position: absolute;
  margin: auto;
  width: 22px;
  height: 22px;
  top: 23px;
  @media screen and (min-width: 768px) {
    top: 48px;
  }
  right: 30px;
  @media screen and (min-width: 768px) {
    right: 30px;
  }
  cursor: pointer;
  .leftright {
    height: 2px;
    width: 22px;
    position: absolute;
    margin-top: 11px;
    background-color: #ffffff;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all 0.3s ease-in;
  }

  .rightleft {
    height: 2px;
    width: 22px;
    position: absolute;
    margin-top: 11px;
    background-color: #ffffff;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-in;
  }

  label {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease-in;
    opacity: 0.99;
    @media screen and (min-width: 768px) {
      opacity: 0;
    }
  }
  .close {
    margin: 30px 0 0 5px;
    position: absolute;
  }

  .close-container:hover .leftright {
    transform: rotate(-45deg);
    background-color: var(--color-petroleum);
  }
  .close-container:hover .rightleft {
    transform: rotate(45deg);
    background-color: var(--color-petroleum);
  }
  @media screen and (hover: hover) {
    .close-container:hover label {
      opacity: 0.99;
    }
  }
}
