.split-hero {
  position: relative;
  color: var(--color-white);
  .col-xs-10:first-child {
    margin-bottom: 30px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--color-green);
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
      &::after {
        content: none;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &::after {
      content: "";
      width: 2px;
      height: calc(100% - 200px);
      left: calc(50% - 2px);
      top: 100px;
      position: absolute;
      background-color: var(--color-green);
    }    
  }  
  .flex-tag {
    flex-basis: 0;
  }
  .flex-text {
    margin-bottom: 0;
    width: 100%;
    margin-top: 20px;
    @media screen and (min-width: 1024px) {
      margin-top: 15px;
    }
  }
  .flex-subtext {
    flex-basis: 0;
    width: 100%;
    margin-bottom: 40px;
  }
  .flex-cta {
    align-self: flex-start;
    background-color: var(--color-white);
    color: var(--color-petroleum);
  }
}

.three-split{
    .col-xs-10:nth-child(2){
      position:relative;
      margin-bottom: 30px;
      &::after{
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #2ea149;
        background-color: var(--color-green);
      }
      @media screen and (min-width: 1024px) {
        margin-bottom: 0;
        &::after {
          content: none;
        }
      }
    }
    
    @media screen and (min-width: 1024px){
      &::before {
        content: "";
        width: 2px;
        height: calc(100% - 200px);
        left: calc(40% - 2px);
        top: 100px;
        position: absolute;
        background-color: var(--color-green);      
      } 
      &::after{
        left: calc(72% - 2px);
      }
    }
  }
